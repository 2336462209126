
import Vue from 'vue';
import getBannerKey from '@root/modules/ads/utils/getBannerKey';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import { Data as ArticleData } from '@root/modules/article/types/article';

interface Props {
  article: ArticleData | null;
}

interface Computed {
  adsGlobal: Record<string, any>;
  ads: string[];
  adsAttributes: {
    mkw: string[];
    mkv: Record<string, string>;
  };
}

interface Methods {
  getKey: (params: Record<string, any>) => string;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    AdFormBanner,
  },
  props: {
    article: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  computed: {
    adsGlobal() {
      return this.$store.getters['adForm/getAdsPlacements'];
    },
    ads() {
      const ads: Record<string, any> = this.$store.getters['adForm/getAdsPlacements'];

      return (ads.tower && ads.tower.mid) || []; // @FIXME: Add fallback?
    },
    adsAttributes() {
      const keywords: string[] = this.$store.getters['adForm/getAdsKeywords'];
      const keyValues: Record<string, string> = this.$store.getters['adForm/getAdsKeyValues'];

      return {
        mkw: keywords,
        mkv: keyValues,
      };
    },
  },
  methods: {
    getKey({ prefix, index }) {
      return getBannerKey({ prefix, route: this.$route, store: this.$store, index });
    },
  },
});
