import Vue from 'vue';
import getCanonicalUrl from '@root/modules/weather/utils/getCanonicalUrl';
interface Methods {
  getLocationUrl: (location: Record<any, any>, isWidth: boolean) => string;
}

export default Vue.extend<unknown, Methods, unknown, unknown>({
  methods: {
    getLocationUrl(location, isWidget) {
      if (typeof isWidget === 'undefined') {
        isWidget = false;
      }

      const { id } = this.$channelConfig('settings');
      const channel = id;
      const path = 'prognoze';
      let locationId = location.placeId ?? '';

      if (
        (location.placeId && /reverseGeocoding:/.test(location.placeId)) ||
        (typeof location.formattedAddress === 'undefined' && location.lat && location.lon)
      ) {
        locationId = `${location.lat},${location.lon}`;
      } else {
        locationId = getCanonicalUrl(location.formattedAddress);
      }
      return isWidget ? `/${channel}/${path}/${locationId}` : `/${channel}/${path}/${locationId}`;
    },
  },
});
