import { gridConfig } from '@core/config/grid.config';

export default function () {
  if (typeof window === 'undefined') {
    return false;
  }

  const screenWidth = window.screen.width;
  const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;

  return screenWidth <= gridConfig.breakpoints.lg || isIpad;
}
