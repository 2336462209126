
import Vue from 'vue';

interface Space {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

interface Props {
  width: string | number;
  height: string | number;
  maxWidth: string;
  space: Space;
  type: string;
  responsiveHeight: boolean;
}

interface Computed {
  classModifier: Record<string, unknown>;
  styles: Record<string, unknown>;
}

interface Methods {
  getMargin: (space: Space) => Record<string, string>;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  props: {
    width: {
      type: [String, Number],
      required: true,
    },
    height: {
      type: [String, Number],
      required: true,
    },
    maxWidth: {
      type: String,
      default: '100%',
      required: false,
    },
    space: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: '',
      required: false,
    },
    responsiveHeight: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    classModifier() {
      return { [`skeleton-loader--type-${this.type}`]: !!this.type };
    },
    styles() {
      const { width, height, maxWidth, responsiveHeight, space } = this;
      const heightInt = typeof height === 'string' ? Number(height.replace(/#px|%/g, '')) : height;
      const widthInt = typeof width === 'string' ? Number(width.replace(/#px|%/g, '')) : width;
      const paddingBottom = responsiveHeight ? `${(heightInt / widthInt) * 100}%` : null;
      const margin = this.getMargin(space);

      return {
        width: typeof width === 'number' ? `${width}px` : width,
        height: !paddingBottom ? (typeof height === 'number' ? `${height}px` : height) : null,
        maxWidth,
        paddingBottom,
        ...margin,
      };
    },
  },
  methods: {
    getMargin(space) {
      const allMargins: Record<string, string> = {};
      for (const [type, value] of Object.entries(space)) {
        allMargins[`margin-${type}`] = value;
      }
      return allMargins;
    },
  },
});
